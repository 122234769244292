<template>
  <div style="display: grid;grid-template-columns: repeat(auto-fill,minmax(300px,1fr));grid-gap: 24px">
    <v-card
      v-for=" i in licenseList"
      :key="i.id"
      class="pa-2 d-flex align-center"
      elevation="0"
    >
      <div class="pa-4 px-8">
        <v-img
          :aspect-ratio="1"
          :src="i.images[0]"
          :style="status !== 'active' ? '-webkit-filter: grayscale(100%);filter: grayscale(100%);' : ''"
          :width="60"
        />
      </div>
      <div class="py-4 px-0">
        <div class="text-body-1">
          {{ i.name }}
        </div>
        <div class="text-body-2 text--secondary">
          {{ i.description }}
        </div>
        <v-chip
          :color="status === 'active' ? 'primary lighten-4 black--text' : status === 'cancel' ? 'red lighten-4 black--text' : 'blue lighten-4 black--text'"
          label
          small
        >
          {{ status === 'active' ? $t('isActive') : status === 'cancel' ? $t('WaitingRenewal') : $t('WaitActivation') }}
        </v-chip>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'LicenseCard',
    props: {
      licenseList: {},
      status: {},
    },
  }
</script>

<style scoped>

</style>
